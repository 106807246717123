import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileIconNamePipe } from "./file-icon-name.pipe";
import {CoinTypeViewPipe} from '@app/_pipes/coin-type-view.pipe';


@NgModule({
  declarations: [
    FileIconNamePipe,
    CoinTypeViewPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FileIconNamePipe,
    CoinTypeViewPipe
  ]
})
export class PipesModule {
}
